const nav = [{
    id: "1",
    label: "基础数据",
    icon: "el-icon-s-data",
    path: "/basics",
    children: [{
        groupid: "1-1",
        label: "人力资源",
        path: "human",
        noCache: true
    }, {
        groupid: "1-2",
        label: "组织架构",
        path: "framework",
        noCache: true
    }, {
        groupid: "1-3",
        label: "场地设置",
        path: "teachingField",
        noCache: true
    }, {
        groupid: "1-4",
        label: "科目数据",
        path: "subjectData",
        noCache: true
    }, {
        groupid: "1-5",
        label: "等级数据",
        path: "rankData",
        noCache: true
    }, {
        groupid: "1-6",
        label: "机构数据",
        path: "organization",
        noCache: true
    }, {
        groupid: "1-7",
        label: "年级数据",
        path: "gradeData",
        noCache: true
    }, {
        groupid: "1-8",
        label: "学校资料",
        path: "schoolData",
        noCache: true
    }, {
        groupid: "1-9",
        label: "合作机构人员",
        path: "cooperate",
        noCache: false
    }, {
        groupid: "1-10",
        label: "人力资源新增",
        path: "addHuman",
        noCache: false
    }, {
        groupid: "1-11",
        label: "人力资源编辑",
        path: "editHuman",
        noCache: false
    }, {
        groupid: "1-12",
        label: "合作机构人员新增",
        path: "addCooperate",
        noCache: false
    }, {
        groupid: "1-13",
        label: "合作机构人员编辑",
        path: "editCooperate",
        noCache: false
    }, {
        groupid: "1-14",
        label: "学校资料新增",
        path: "addSchoolData",
        noCache: false
    }, {
        groupid: "1-15",
        label: "学校资料编辑",
        path: "editSchoolData",
        noCache: false
    }, {
        groupid: "1-16",
        label: "等级数据新增",
        path: "addRankData",
        noCache: false
    }, {
        groupid: "1-17",
        label: "等级数据编辑",
        path: "editRankData",
        noCache: false
    }, {
        groupid: "1-18",
        label: "机构数据新增",
        path: "addOrganization",
        noCache: false
    }, {
        groupid: "1-19",
        label: "机构数据编辑",
        path: "editOrganization",
        noCache: false
    }, {
        groupid: "1-20",
        label: "学科数据新增",
        path: "addSubjectData",
        noCache: false
    }, {
        groupid: "1-21",
        label: "学科数据编辑",
        path: "editSubjectData",
        noCache: false
    }, {
        groupid: "1-22",
        label: "教学与考试场地新增",
        path: "addTeachingField",
        noCache: false
    }, {
        groupid: "1-23",
        label: "教学与考试场地编辑",
        path: "editTeachingField",
        noCache: false
    }, {
        groupid: "1-24",
        label: "年级数据新增",
        path: "addGradeData",
        noCache: false
    }, {
        groupid: "1-25",
        label: "年级数据编辑",
        path: "editGradeData",
        noCache: false
    }]
}, {
    id: "2",
    label: "学员管理",
    icon: "el-icon-s-custom",
    path: "/student",
    children: [{
        groupid: "2-1",
        label: "学生基础数据",
        path: "studentData",
        noCache: true
    }, 
    {
        groupid: "2-2",
        label: "学员授权",
        path: "studentEmpower",
        noCache: false
    }, 
    {
        groupid: "2-3",
        label: "检索学员资料",
        path: "retrieval",
        noCache: true
    }, {
        groupid: "2-4",
        label: "批量处理学员相片",
        path: "batchProcessing",
        noCache: true
    }, {
        groupid: "2-5",
        label: "学生基础数据新增",
        path: "addStudentData",
        noCache: false
    }, {
        groupid: "2-6",
        label: "学生基础数据编辑",
        path: "editStudentData",
        noCache: false
    }, {
        groupid: "2-7",
        label: "学员授权详情",
        path: "detailsStudentEmpower",
        noCache: false
    }, {
        groupid: "2-8",
        label: "学员资料详情",
        path: "retrievalEmpower",
        noCache: false
    },{
        groupid: "2-9",
        label: "学员信息",
        path: "studentNews",
        noCache: false
    }]
}, {
    id: "3",
    label: "考务管理",
    icon: "el-icon-edit-outline",
    path: "/examination",
    children: [{
        groupid: "3-1",
        label: "设置考试场次",
        path: "examinationSessions",
        noCache: true
    }, {
        groupid: "3-2",
        label: "导入报名数据",
        path: "registrationData",
        noCache: true
    }, {
        groupid: "3-3",
        label: "考生报考登记",
        path: "register",
        noCache: true
    }, {
        groupid: "3-4",
        label: "考生考场安排",
        path: "arrange",
        noCache: true
    }, {
        groupid: "3-5",
        label: "打印准考证与点名表",
        path: "admissionTicket",
        noCache: true
    }, {
        groupid: "3-6",
        label: "录入学员考试成绩",
        path: "testResult",
        noCache: true
    }, {
        groupid: "3-7",
        label: "处理学生留言",
        path: "studentMsg",
        noCache: true
    }, {
        groupid: "3-8",
        label: "学员资料", //单科考试-学员资料
        path: "singleData",
        noCache: false
    }, {
        groupid: "3-9",
        label: "单科考场新增",
        path: "addSingle",
        noCache: false
    }, {
        groupid: "3-10",
        label: "学员资料", //综合考场-学员资料
        path: "syntheticalData",
        noCache: false
    }, {
        groupid: "3-11",
        label: "综合考场新增",
        path: "addSynthetical",
        noCache: false
    }, {
        groupid: "3-12",
        label: "考生报考登记表",
        path: "registerForm",
        noCache: false
    }, {
        groupid: "3-13",
        label: "学员信息",
        path: "registerDetails",
        noCache: false
    }, {
        groupid: "3-14",
        label: "考生报考登记表新增",
        path: "addRegister",
        noCache: false
    }, {
        groupid: "3-15",
        label: "考生报考登记表编辑",
        path: "editRegister",
        noCache: false
    }, {
        groupid: "3-16",
        label: "导入表编辑",
        path: "editImportTable",
        noCache: false
    }, {
        groupid: "3-17",
        label: "学员管理",
        path: "studentAdmin",
        noCache: false
    }, {
        groupid: "3-18",
        label: "考生考场安排新增",
        path: "addArrange",
        noCache: false
    }, {
        groupid: "3-19",
        label: "考生考场安排编辑",
        path: "editArrange",
        noCache: false
    }, {
        groupid: "3-20",
        label: "书画学院打印准考证与点名表",
        path: "studentDetails",
        noCache: false
    }, {
        groupid: "3-21",
        label: "本场名单",
        path: "nameList",
        noCache: false
    }, {
        groupid: "3-22",
        label: "书画学院录入学员考试成绩表",
        path: "addTestResult",
        noCache: false
    }, {
        groupid: "3-23",
        label: "学员考试成绩编辑",
        path: "editTestResult",
        noCache: false
    }, {
        groupid: "3-24",
        label: "学员信息",
        path: "testResultDetails",
        noCache: false
    }, {
        groupid: "3-25",
        label: "录入学员考试成绩更多",
        path: "testResultMore",
        noCache: false
    }, {
        groupid: "3-26",
        label: "审核日记",
        path: "auditJournal",
        noCache: false
    }, {
        groupid: "3-27",
        label: "处理留言编辑",
        path: "editStudentMsg",
        noCache: false
    }, {
        groupid: "3-28",
        label: "考试大类新增",
        path: "addExamBigClass",
        noCache: false
    }, {
        groupid: "3-29",
        label: "考试大类编辑",
        path: "editExamBigClass",
        noCache: false
    },{
        groupid: "3-30",
        label: "综合考场编辑",
        path: "editSynthetical",
        noCache: false
    },{
        groupid: "3-31",
        label: "单科考试编辑",
        path: "editSingle",
        noCache: false
    },]
}, {
    id: "4",
    label: "教务管理",
    icon: "el-icon-school",
    path: "/educational",
    children: [{
        groupid: "4-1",
        label: "培训班级类别",
        path: "classCategory",
        noCache: true
    }, {
        groupid: "4-2",
        label: "培训班级列表",
        path: "classList",
        noCache: true
    }, {
        groupid: "4-3",
        label: "培训新生注册",
        path: "trainNewborn",
        noCache: true
    }, {
        groupid: "4-4",
        label: "班级登记管理",
        path: "classRegister",
        noCache: true
    }, {
        groupid: "4-5",
        label: "检索辅导员学员名单",
        path: "retrievalStudents",
        noCache: true
    }, {
        groupid: "4-6",
        label: "培训学员考勤",
        path: "studentClockIn",
        noCache: true
    }, {
        groupid: "4-7",
        label: "检索学员出勤统计表",
        path: "retrievalTable",
        noCache: true
    }, {
        groupid: "4-8",
        label: "教学场地使用情况",
        path: "siteUsage",
        noCache: true
    }, {
        groupid: "4-9",
        label: "培训班级类别新增",
        path: "addClassCategory",
        noCache: false
    }, {
        groupid: "4-10",
        label: "培训班级类别编辑",
        path: "editClassCategory",
        noCache: false
    }, {
        groupid: "4-11",
        label: "授课老师新增",
        path: "addTeacher",
        noCache: false
    }, {
        groupid: "4-12",
        label: "授课老师编辑",
        path: "editTeacher",
        noCache: false
    }, {
        groupid: "4-13",
        label: "培训班级列表新增",
        path: "addClassList",
        noCache: false
    }, {
        groupid: "4-14",
        label: "培训班级列表编辑",
        path: "editClassList",
        noCache: false
    }, {
        groupid: "4-15",
        label: "学员信息",
        path: "studentInfo",
        noCache: false
    }, {
        groupid: "4-16",
        label: "排课表新增",
        path: "addTimetable",
        noCache: false
    }, {
        groupid: "4-17",
        label: "排课表编辑",
        path: "editTimetable",
        noCache: false
    }, {
        groupid: "4-18",
        label: "培训新生注册新增",
        path: "addTrainNewborn",
        noCache: false
    }, {
        groupid: "4-19",
        label: "培训新生注册编辑",
        path: "editTrainNewborn",
        noCache: false
    }, {
        groupid: "4-20",
        label: "同班学员",
        path: "classmate",
        noCache: false
    }, {
        groupid: "4-21",
        label: "单据汇总",
        path: "billSummary",
        noCache: false
    }, {
        groupid: "4-22",
        label: "班级登记管理新增",
        path: "addClassRegister",
        noCache: false
    }, {
        groupid: "4-23",
        label: "班级登记管理编辑",
        path: "editClassRegister",
        noCache: false
    }, {
        groupid: "4-24",
        label: "班级登记管理-学员",
        path: "trainee",
        noCache: false
    }, {
        groupid: "4-25",
        label: "学员新增",
        path: "addTrainee",
        noCache: false
    }, {
        groupid: "4-26",
        label: "学员编辑",
        path: "editTrainee",
        noCache: false
    }, {
        groupid: "4-27",
        label: "收款码",
        path: "collectionCode",
        noCache: false
    }, {
        groupid: "4-28",
        label: "班级登记管理-更多",
        path: "classRegisterMore",
        noCache: false
    }, {
        groupid: "4-29",
        label: "转班",
        path: "shiftTransfer",
        noCache: false
    }, {
        groupid: "4-30",
        label: "明细表",
        path: "detailedTable",
        noCache: false
    }, {
        groupid: "4-31",
        label: "查看更多",
        path: "seeMore",
        noCache: false
    }, {
        groupid: "4-32",
        label: "培训学员考勤新增",
        path: "addStudentClockIn",
        noCache: false
    }, {
        groupid: "4-33",
        label: "培训学员考勤编辑",
        path: "editStudentClockIn",
        noCache: false
    }, {
        groupid: "4-34",
        label: "培训学员考勤-学员",
        path: "pupil",
        noCache: false
    }, {
        groupid: "4-35",
        label: "培训学员考勤-学员新增",
        path: "addPupil",
        noCache: false
    }, {
        groupid: "4-36",
        label: "培训学员考勤-学员编辑",
        path: "editPupil",
        noCache: false
    }, {
        groupid: "4-37",
        label: "培训学员考勤-更多",
        path: "studentClockInMore",
        noCache: false
    }, {
        groupid: "4-38",
        label: "检索学员出勤统计表详情",
        path: "retrievalTableDetails",
        noCache: false
    }, {
        groupid: "4-39",
        label: "统计表",
        path: "censusTable",
        noCache: false
    }, {
        groupid: "4-40",
        label: "教学场地使用情况统计表",
        path: "siteUsageCensus",
        noCache: false
    }, {
        groupid: "4-41",
        label: "培训班级类别--班级",
        path: "classData",
        noCache: false
    }, {
        groupid: "4-42",
        label: "培训班级类别--授课老师",
        path: "teacherData",
        noCache: false
    }]
}, {
    id: "5",
    label: "财务作业",
    icon: "el-icon-coin",
    path: "/finance",
    children: []
}, {
    id: "6",
    label: "日常事务",
    icon: "el-icon-date",
    path: "/dailyAffairs",
    children: []
}, {
    id: "7",
    label: "库存管理",
    icon: "el-icon-receiving",
    path: "/stockControl",
    children: []
}, {
    id: "8",
    label: "业务统计",
    icon: "el-icon-pie-chart",
    path: "/business",
    children: [{
        groupid: "8-1",
        label: "考场统计表",
        path: "examinationRoom",
        noCache: true
    }, {
        groupid: "8-2",
        label: "考生统计表",
        path: "examineeCensus",
        noCache: true
    }, {
        groupid: "8-3",
        label: "考生成绩表",
        path: "examineeResult",
        noCache: true
    }, {
        groupid: "8-4",
        label: "考生科目等级统计表",
        path: "examineeSubject",
        noCache: true
    }, {
        groupid: "8-5",
        label: "考场统计表详情",
        path: "statisticsDetails",
        noCache: false
    }, {
        groupid: "8-6",
        label: "考生统计表详情",
        path: "examineeDetails",
        noCache: false
    }, {
        groupid: "8-7",
        label: "考生成绩表详情",
        path: "resultDetails",
        noCache: false
    }, {
        groupid: "8-8",
        label: "考生成绩表更多",
        path: "resultMore",
        noCache: false
    }, {
        groupid: "8-9",
        label: "明细表",
        path: "detailedOne",
        noCache: false
    }, {
        groupid: "8-10",
        label: "明细表",
        path: "detailedTwo",
        noCache: false
    }]
}, {
    id: "9",
    label: "权限管理",
    icon: "el-icon-s-tools",
    path: "/rbac",
    children: [{
        groupid: "9-1",
        label: "角色管理",
        path: "roles",
        noCache: true
    }, {
        groupid: "9-2",
        label: "新增功能权限",
        path: "functional",
        noCache: false
    }, {
        groupid: "9-3",
        label: "编辑功能权限",
        path: "editfunctional",
        noCache: false
    }]
}
// , {
//     id: "10",
//     label: "官网设置",
//     icon: "el-icon-s-tools",
//     path: "",
//     children: [{
//         groupid: "10-1",
//         label: "官网设置",
//         path: "newsDynamic",
//         noCache: true
//     }]
// },
]
export default nav
